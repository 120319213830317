import React from 'react';
import {  Form, FormGroup, FormText, Alert, View, InputGroup, InputGroupText, InputGroupAddon,  Input,  Fade, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-activity/lib/Dots/Dots.css';
import "../../../node_modules/video-react/dist/video-react.css";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import SoundDefinition from './SoundDefinition'
import { withTranslation } from 'react-i18next'
import '../../i18n'
/*const styles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));
*/

const styles = theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
});

class SoundDefinitionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      sound: this.props.sound

    };


  this.toggle = this.toggle.bind(this);

  }


  static getDerivedStateFromProps(props, state) {

    if ( props.sound !== state.sound ){
      console.log('getderived from sounddefmodal: ', props.sound)
      return {
        sound: props.sound
      }
    }
    return null;
  }


  toggle(){

    /*
    if (!this.state.modal){
      this.setState({sound: this.props.sound})
    }
    */
    


    this.setState({ modal: !this.state.modal})
  }


  choosenSoundCallback(sound){
      this.props.callback(sound)
      this.setState({sound})
      this.toggle()
  }


  render() {


    const {t} = this.props; 

    return (
      <div>

        
        {
        this.state.sound && this.state.sound.hasOwnProperty('id') ? 
        <Button color="primary" onClick={this.toggle}>{this.state.sound.title} - {this.state.sound.artistName} - {t('sounddefinitionmodal.changemusic')} </Button>
        : <Button color="primary" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        }

        <Modal size="xl" style={{ maxWidth: '1600px', width: '80%' }} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>



          <ModalHeader toggle={this.toggle}>{t('sounddefinitionmodal.musicselection') + ' '}  {this.props.id}</ModalHeader>
          <ModalBody>
          <SoundDefinition callback={(c) => this.choosenSoundCallback(c)} />

	          </ModalBody>
          <ModalFooter>


			<Button  color="primary" onClick={() => this.toggle()}>{t('general.closetext')}</Button>



          </ModalFooter>



        </Modal>


      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(SoundDefinitionModal));
