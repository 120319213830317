import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid2 from '@material-ui/core/Grid';
import '../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import StoryboardUpdatePopup from './StoryboardUpdatePopup';
import 'react-activity/lib/Dots/Dots.css';
import SingleVideoPopup from './SingleVideoPopup'
import { withTranslation } from 'react-i18next'
import '../../i18n'
import { SnackbarProvider, withSnackbar } from 'notistack';
const settings = require('../../settings');


const styles = theme => ({
  root: {
    width: '100%',

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular

  },

  column: {
    flexBasis: '45%'
  },

  margin: {
    margin: theme.spacing.unit,
    //margin: 'auto', 

  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  button: {
    margin: theme.spacing.unit,

  },

  center: {
    margin: 'auto'

  },
  fab3: {
    margin: theme.spacing.unit,
    display: 'flex',
    //marginRight: "auto" ,
    //justifyContent: 'flex-end',
    // position: 'absolute',
    // right: '0',
    float: 'right',
    background: '#28a745'
  },


});



class MovieBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlvalue: [],
      logo: "",
      storyboarddata: null,
      language: '',
      sgid: null

    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }




  async componentDidMount() {

    var UrlValue = await this.getHashFromUrl();
    var langCode = await this.getLangCode();
    var sg = await this.getSupergroupID();
    this.changeLanguage(langCode)
    var IDUrlParameterValue = null
    await this.setState({
      urlvalue: UrlValue,
      language: langCode,
      sgid: sg
      //logo: "https://www.joobster.at/wp-content/uploads/2018/05/joobster_logo_black-1024x460.jpg"
    })
    //console.log('url value is: ', this.state.urlvalue)


    await this.getStoryboardFromJoobsterServer()
  }



  changeLanguage = (lC) => {
    this.props.i18n.changeLanguage(lC)
  }


  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }


  async getLangCode() {

    return this.getUrlVars()['languageCode'];
  }

  async getSupergroupID() {

    return this.getUrlVars()['sg'];
  }

  async getHashFromUrl() {


    return this.getUrlVars()['questionhash'];
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }


  async storyboardCallback() {
    await this.getStoryboardFromJoobsterServer()
    // window.location.reload();

  }


  async getStoryboardFromJoobsterServer() {

    const { t } = this.props;
    //console.log('Storyboardurl is: ', settings.internalstorypath + '/' + this.state.urlvalue)

    await fetch(settings.internalstorypath + '/' + this.state.urlvalue, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) =>
      res.json()).then(async (data) => {


        await data.storyboard.shots.map(f => f.expanded = false)

        const { t } = this.props;
        await this.setState({
          storyboarddata: data.storyboard
        })


        console.log('data is:  ', data.storyboard)


        this.props.enqueueSnackbar(t('mainsite.getStoryboards.Success.title'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

      })
      .catch((err) => {

        this.props.enqueueSnackbar(t('mainsite.getStoryboards.Error.text') + ' : ' + err, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

      });

  }


  handleKeyPress(event) {
    if (event.key == 'Enter') {
      this.searchFieldClicked();
    }
  }

  render() {

    const { t } = this.props;

    return (
      <div>


        <div className={styles.root}>
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div className={styles.column}>
                <img top="true" width="10%" src={this.state.logo ? this.state.logo : 'https://www.joobster.at/wp-content/uploads/2018/05/joobster_logo_black-1024x460.jpg'} alt="Firmenlogo" />  {t('mainsite.slogan')}
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>

              <Grid2 container className={styles.container} >
                <Grid2 item xs={12} >
                  <Grid2 container spacing={16} alignitems="center" justify="center" direction="row" >

                    <p>{t('mainsite.storyboarding')}</p>

                  </Grid2>

                </Grid2>
              </Grid2>

              <SingleVideoPopup buttonLabel={t('mainsite.explainvideo')} />

            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>

        {
          this.state.storyboarddata ? <div>

            <StoryboardUpdatePopup languageCode={this.state.language} mediasCallback={() => this.storyboardCallback()} sgid={this.state.sgid} hash={this.state.urlvalue} storyboardcallback={() => { window.location.reload() }} storyboarddata={this.state.storyboarddata} />

          </div> :
            null
        }
      </div>
    );

  }

}

export default withSnackbar(withTranslation()(MovieBrowser));

