
import React, { Component } from 'react';
import {Label, Input, Form, FormGroup, Alert} from 'reactstrap';
import { withTranslation } from 'react-i18next'
import '../../i18n'

class Rowcheckbox extends Component
{

	constructor(props){
		super(props);
		this.state = { checked: this.props.isIn };
		this.checkboxHandler = this.checkboxHandler.bind(this);
	}


  checkboxHandler(e){
     var checkstate =  !this.state.checked;
		 this.setState({ checked: checkstate });
		this.props.variableTransfer(this.props.data.id, this.props.data, checkstate);

	}

	render() {
    const {t} = this.props;
		return(
			<div style={{textAlign: "center"}}>

				<Form >
				<FormGroup  check>
				<Input style={{ transform: "scale(2,2) translateY(4px) " }} id={this.props.data.id} checked={this.state.checked} type={this.props.type} onChange={ (e) => this.checkboxHandler(e)} />{' '}
				</FormGroup>
				</Form>
			</div>
		)
	}
}

export default  withTranslation()(Rowcheckbox);
