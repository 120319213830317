module.exports = {

    storypath: 'https://storyboard.jlnk.at', 
    
  //  surveypath: 'https://api2-demo.joobster.at/survey', //demo
    api_base_path: '',
    internalstorypath: 'https://viewer2.joobster.at/storyboard', 
   // internalstorypath: 'https://mystory.jlnk.at/storyboard', 
    api_service_path: 'https://service2.joobster.at',
//    internalsurveypath: 'https://viewer2-demo.joobster.at/survey' //demo
	
}
