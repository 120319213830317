
    import React from 'react';
    import ChipInput from 'material-ui-chip-input'
    import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
    import { withTranslation } from "react-i18next";
    import i18n from "../../i18n" 

class EquipmentAdder extends React.Component{
  constructor (props) {
    super(props)
    this.state = {
      chips: this.props.equipment || []
    }
  }

  

  static getDerivedStateFromProps(props, state){
      if (JSON.stringify(props.equipment) !== JSON.stringify(state.chips)){
          console.log('now chips changed: ', props.equipment)
          return {
              chips: props.equipment
          }
      }
      return null
  }
  

  async handleAdd (...chips) {


   
   await this.props.equipmentCallback([...this.state.chips, ...chips])
   this.setState({})
  }

async  handleDelete (deletedChip) {

   
   await this.props.equipmentCallback(this.state.chips.filter((c) => c != deletedChip))
   this.setState({})

  }

  render () {
      const {t} = this.props
    return (
      <MuiThemeProvider>
      <ChipInput

        {...this.props}
        hintText={t('storyboard.storyboardcreator.typeequipment')}
        //defaultValue={this.state.chips}
        value={this.state.chips}

        onPaste={(event) => {
          const clipboardText = event.clipboardData.getData('Text')

          event.preventDefault()

          this.handleAdd(...clipboardText.split('\n').filter((t) => t.length > 0))

          if (this.props.onPaste) {
            this.props.onPaste(event)
          }
        }}
        onAdd={(chip) => this.handleAdd(chip)}

        onDelete={(deletedChip) => this.handleDelete(deletedChip)}
        fullWidth

        />
      </MuiThemeProvider>
    )
  }
}

export default withTranslation()(EquipmentAdder);



