import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid2 from '@material-ui/core/Grid';
import Swal from 'sweetalert2';
import '../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withStyles } from '@material-ui/core/styles';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter, selectFilter, multiSelectFilter, Comparator } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import DelIcon from '@material-ui/icons/Delete';
import PlayAudio from 'react-audio-player'
import Chip from '@material-ui/core/Chip';
import Quoting from './Quoting'
import { withTranslation } from 'react-i18next'
import '../../i18n'
const settings = require('../../settings');




const gridstyles = theme => ({
  root: {
    flexGrow: 1,
  },

  paper: {
    height: 140,
    width: 100,
  },
  

});

const appbarstyles = {
  root: {
    flexGrow: 1,
  },
};


const avatarstyles = {
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
};



const styles = theme => ({
  root: {
    width: '100%',     
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular

  },
  fab: {
    margin: theme.spacing.unit,
    background: 'red'

  },
  column: {
    flexBasis: '45%'
  }, 

  margin: {
    margin: theme.spacing.unit,    
    //margin: 'auto', 

  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  button: {
    margin: theme.spacing.unit,
    
  }, 

  center: {
    margin: 'auto'

  }


});



class SoundDefinition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      soundData: [], 
      logo: "", 
      images: [], 
      loading: true, 
      sound: {}
    };


    this.handleKeyPress = this.handleKeyPress.bind(this);


  }





  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }



  async componentDidMount() {
    const {t} = this.props; 
    let returnFetch = await this.getSoundsFromJoobsterDB()

    //console.log('SoundData after componentDidMount: ', returnFetch)
    if (returnFetch.status === "ok") {

      this.setState({
        soundData: returnFetch.sounds, 
        loading: false
      })
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: t('storyboardupdatepopup.musicsuccessfullyloaded'),
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      Swal.fire({
        title: t('storyboardupdatepopup.musicloadingerror.title'),
        position: 'center',
        text: t('storyboardupdatepopup.musicloadingerror.text'),
        type: 'error',
        animation: true,
        customClass: {
          popup: 'animated tada'
        },
        showConfirmButton: false,
        timer: 1500
        //confirmButtonText: 'Cool'
      })
    }


  }







async getSoundsFromJoobsterDB() {

    var awaitFetch =   await fetch(settings.api_base_path + '/api/1.0/public/sounds', { method: 'GET' }) 

    let returnFetch = await awaitFetch.json()

      console.log('fetchval ', returnFetch)


      return returnFetch

    }



handleKeyPress(event){
  if(event.key == 'Enter'){    
    this.searchFieldClicked();
  }
}


   render() {

    var localthis = this;
    const { classes,t } = this.props;



    
    function IDFormatter(cell, row, rowIndex, formatExtraData) {
      return (
          <p>{row.id}</p>
      );
  }    

  function titleFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <p>{row.title}</p>
    );
} 
function licenseFormatter(cell, row, rowIndex, formatExtraData) {
  const {t} = localthis.props; 
  return (
    <div>
      <p><strong>{row.title}</strong> by {row.artistName}</p>
      <p>{row.license}</p><br/>
      <a href={row.link} target="_blank" > {t('sounddefinition.linktolicense')}</a>
      </div>
  );
} 
function durationFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      <p>{row.duration}</p>      
      </div>
  );
} 
function genreFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    row.genre.map( (gen, idx) => 
        idx !== row.genre.length-1 ? gen + ", " : gen
      )
      
  );
} 

  function soundFormatter(cell, row, rowIndex, formatExtraData) {


      return (
          <div>
              
              <PlayAudio 
                 
              preload="none"                          
                            controls
                            src={row.url} />              
              </div>
      );
  }      


    const columns = [
      {
        dataField: 'id', 
        text: 'Sound ID', 
        hidden: true, 
        formatter: IDFormatter, 
        //filter: textFilter(), 
              sort: true, 
              
        headerStyle: () => {
          return { width: "5%" };
              }	
                            
      }, 
      {
      dataField: 'title', 
      text: t('sounddefinition.tabletitle'), 
      headerTitle: false, 
      formatter: titleFormatter, 
          filter: textFilter(), 
          headerStyle: () => {
              return { width: "20%" };
          }        
          
      }, 
      {
        dataField: 'genre', 
        text: t('sounddefinition.tablegenre'), 
        headerTitle: false, 
        formatter: genreFormatter, 
            filter: textFilter(), 
            headerStyle: () => {
                return { width: "10%" };
            }        
            
            
        },       
{
  dataField: 'url', 
  text: t('sounddefinition.tablesound'), 
  formatter: soundFormatter, 
  //    filter: textFilter(), 
      headerStyle: () => {
          return { width: "25%" };
      }        
      
  }, 
  {
    dataField: 'license', 
    text: t('sounddefinition.tablelicense'), 
    formatter: licenseFormatter, 
    //    filter: textFilter(), 
        headerStyle: () => {
            return { width: "30%" };
        }        
        
    }   , 
    {
      dataField: 'duration', 
      text: t('sounddefinition.tableduration'), 
      formatter: durationFormatter, 
          filter: numberFilter({
            getFilter: (filter) => {
              // pricerFilter was assigned once the component has been mounted.
              this.state.durationFilter = filter;
            },
          headerStyle: () => {
              return { width: "20%" };
          }   })     
          
      }     
  
  
    ]; 
  
  
  
    const selectRow = {
      mode: 'checkbox',
      classes: 'selection-row', 
      //headerFormatter: selectFormatter, 
      onSelect: (row, isSelect, rowIndex, e) => {
      //	if (isSelect) this.setSelectedRow(row.id, row, true); 	
     // console.log('following row choosen', row), 
      this.setState({sound: row})
      this.props.callback(row)
      },
      onSelectAll: (isSelect, rows, e) => {
        console.log(isSelect);
        console.log(rows);
        console.log(e);
      }
         
      };
          
          /*
      function selectFormatter(column, colIndex, { sortElement, filterElement }) {
      return (
        <div style={ { display: 'flex', flexDirection: 'column' } }>
        { filterElement }
        { column.text }
        { sortElement }
        </div>
      );
      }
  */
  
      const rowEvents = {
        onClick: (e, row, rowIndex) => {
          console.log(row);
        }
      }	
  
      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          {t('sounddefinition.tablepagination.soundtrack')} { from } - { to } {t('sounddefinition.tablepagination.from')} { size } 
        </span>
      );
      

      const rowStyle2 = (row, rowIndex) => {
        const style = {};
        if (rowIndex%2== 0) {
          style.backgroundColor = '#fbca30';
        } else {
          style.backgroundColor = '#ffffff';
        }

        return style;
      };

      const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: t('general.tablefirstpagetext'),
        prePageText: t('general.tableprepagetext'),
        nextPageText: t('general.tablenextpagetext'),
        lastPageText: t('general.tablelastpagetext'),
        nextPageTitle: t('general.tablenextpagetitle'),
        prePageTitle: t('"general.tableprepagetitle'),
        firstPageTitle: t('general.tablefirstpagetitle'),
        lastPageTitle: t('general.tablelastpagetitle'),
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{
          text: '10', value: 10
        }, {
          text: '25', value: 25
        }, {
          text: '50', value: 50
        }, {
          text: 'All', value: localthis.state.soundData && localthis.state.soundData!= null ? localthis.state.soundData.length : 0
        }] // A numeric array is also available. the purpose of above example is custom the text
      };




    return (
      <div>
 


 <div className={styles.root}>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>    
        <div className={styles.column}>        
    <img top="true" width="10%"   src={this.state.logo ? this.state.logo : 'https://www.joobster.at/wp-content/uploads/2018/05/joobster_logo_black-1024x460.jpg'} alt="Firmenlogo" /> 
        </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>


              <Grid2 container className={styles.container} >
                <Grid2 item xs={12} >

                  <Grid2 container spacing={16} alignitems="center" justify="center" direction="row" >

                    
                  <Quoting />
                   
                  </Grid2>                  
                 
                </Grid2>
              </Grid2>
            
      
            
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </div>

      {


        this.state.soundData && this.state.soundData.length > 0 ? 
     
          <div style={{ margin: "10px"}}>
            <BootstrapTable keyField='id'
             
              data={this.state.soundData}
              filter={filterFactory()}
              columns={columns}
              selectRow={selectRow}
              rowEvents={rowEvents}
              pagination={paginationFactory(options)}
              rowStyle={ rowStyle2 } 
            />
            </div>
        : null

          
   }

</div>



    );


  }

  
}

export default  withTranslation()(withStyles(styles)(SoundDefinition));

