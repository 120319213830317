import React, { Component } from 'react';
import 'react-activity/lib/Dots/Dots.css';
import { withStyles } from '@material-ui/core/styles';
import {Button, Alert, Badge ,Card, CardImg, CardText, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import {CSVLink} from 'react-csv';
import { withTranslation } from 'react-i18next'
import '../../i18n'
//const settings = require('../../settings');
const styles = theme => ({
    fab: {
      margin: theme.spacing.unit,
      background: 'red'

    },
    extendedIcon: {
      marginRight: theme.spacing.unit,
    },
  });

class CSV_PDFGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
       //   reportingValue : [],
          CSVDATA_openquestions : []

        };
        this.csvExporter = this.csvExporter.bind(this);
        this.generatePDF = this.generatePDF.bind(this);

    }


    mapToSeconds(text){
      const {t} = this.props; 
      let seconds = 0
      if (text.includes(t('general.placeholder'))){
        seconds = text.split(t('general.placeholder')).length - 1
       // console.log('as Platzhalter: ', seconds)
      }else{
        seconds = Math.ceil( text.length / 15 )
       // console.log('SECONDS FROM WORDS AS TEXT: ' + text + " seconds: " + seconds)
      }
    
    
      return seconds
    
    }

  csvExporter() {
    const {t} = this.props;
    var shotarray = [];

    shotarray = this.props.storyboarddata.shots.map(
      (shot, index) => {


        return ([{ text: (index + 1).toString(), bold:true, fontSize: 10, alignment: "center",  tocItem: true }, { text: shot.description, tocItem: true }, { text: shot.question }, { text: shot.answer.includes(t('general.placeholder')) ? '' : shot.answer }, { text: this.mapToSeconds(shot.answer) }])
      }
    )

    var data = [

      [{ text: t('pdfgenerator.shotnr') + ' ', fillColor: '#cd0a1c', color: '#fff' }, { text: t('pdfgenerator.description') + ' ', fillColor: '#cd0a1c', color: '#fff' }, { text: t('pdfgenerator.question') + ' ',color: '#fff', fillColor: '#cd0a1c' }, { text: t('pdfgenerator.answer') + ' ',color: '#fff', fillColor: '#cd0a1c' }, { text: t('pdfgenerator.duration') + ' ',color: '#fff', fillColor: '#cd0a1c' }]

    ].concat(shotarray)

    this.setState({ CSVDATA_openquestions: data });
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }


  
  async generatePDF()
  {
    
    let shotanswercharacters = 0
    this.props.storyboarddata.shots.map(shot => shotanswercharacters = shotanswercharacters + (shot.answer || "" ? shot.answer.length : 0) )
    const {t} = this.props; 
    await this.csvExporter();
    let equipment = this.props.storyboarddata.shots.map(shot => shot.equipment) 
    equipment = equipment.filter((e) => { return e.length > 0})
    console.log('equipment after filter: ', equipment)
    let equipmentstr = ""
    equipment = equipment.map( (earray, idx) => {
      console.log('index is: ',idx)
      if (idx !== equipment.length-1) return earray.map( (str) => { equipmentstr += str + ', '} )
      else
      if (idx === equipment.length-1) {
        console.log('now in last elem: earray', earray)
        return earray.map( (str2, idx2) => { if (earray.length-1 !== idx2) equipmentstr += str2 + ', '; else equipmentstr+= str2} )
      }
    })

    var dd = {
      info: {
        title: 'joobster storyboard document',
        author: 'joobster gmbh',
        subject: this.props.storyboarddata.name,
       // keywords: 'keywords for document',
       creator: 'joobster',
       producer: 'joobster'
      },
      content: [
        {
          image: await this.getBase64ImageFromURL(this.props.logo),
          width: 80,
          style: 'imageright'
        },
        ' ',
        {text: t('pdfgenerator.generatepdf.storyboard') + ' \"'+this.props.storyboarddata.name + '\"', style: 'header'},
        this.props.storyboarddata.note,
        ' ',
  /*      {
          toc: {
            // id: 'mainToc'  // optional
            title: {text: 'INDEX', style: 'header'},
          } },
          ' ',
          */
        {text: t('pdfgenerator.generatepdf.tookcontent') , style: 'subheader'},

        {
          style: 'tableExample',

          table: {
            widths: [ 30, '*', 110, 110, 30],

            body: this.state.CSVDATA_openquestions
          }
        }, 
        {text: t('storyboard.uploadmodal.equipment') + ' : ' + equipmentstr , style: 'subheader'},
        ' ',
        {text: t('pdfgenerator.generatepdf.totallength') + Math.ceil( shotanswercharacters / 15 ), style: 'subheader'}
      ],
      footer: function(currentPage, pageCount) {
        return {
        text: currentPage.toString() + ' '+t('pdfgenerator.generatepdf.bottomline.pagefrom')+' ' + pageCount + " | " + Date(Date.now()).toString() + '| www.joobster.at | +43 699 18 24 20 58',
        style: 'footer',

      }
    },

      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        footer: {
          fontSize: 8,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          fontSize: 8,
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: 'black'
        },
        imageright: {
          alignment: 'right'
        }
      },
      defaultStyle: {
        // alignment: 'justify'
      }

      }
      var pdfMake = require('pdfmake/build/pdfmake.js');
      var vfsFonts = require('pdfmake/build/vfs_fonts.js');




      pdfMake.vfsFonts = vfsFonts;
      pdfMake.vfs = vfsFonts.pdfMake.vfs;
      pdfMake.createPdf(dd).download('joobster_storyboard_'+ this.props.storyboarddata.name ?this.props.storyboarddata.name: "undefined" +'.pdf');
      pdfMake.createPdf(dd).open();


  }



        render(){
          const {t} = this.props; 

            return(

                <div>

                    <Row>
                        <Col>

                            <Button color="success" onClick={this.generatePDF}>{t('pdfgenerator.pdfbuttontext')}</Button> {' '}
                            
                        </Col>
                    </Row>

                </div>


            )
        }




}


export default withTranslation()(withStyles(styles)(CSV_PDFGenerator));
