import React, {Component} from 'react';
import { Row, Col,  InputGroup, InputGroupAddon, InputGroupButton,  Fade, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Container,
  Card, CardBody, CardHeader, CardFooter, Badge as Badge2, CardTitle,  Form, FormGroup,  FormFeedback } from 'reactstrap';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import settings from '../../settings';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField'

import { makeStyles } from '@material-ui/core/styles';
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';
import Reload from '@material-ui/icons/Update';
import  PDFGenerator from './CSV_PDFGenerator'
import EmailComponent from './EmailComponent'
import ProgressBar from 'react-bootstrap/ProgressBar'
import 'bootstrap/dist/css/bootstrap.min.css';
import FillShot from './FillShot'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SaveButton from '@material-ui/icons/Save'
import Switch from '@material-ui/core/Switch';
import {ColorPicker, ColorButton, Color} from 'material-ui-color'
import RemoveIcon from '@material-ui/icons/Remove'
import SketchExample from './SketchExample'
import UploadModal from './UploadModal'
import SoundDefinitionModal from './SoundDefinitionModal'
import { withTranslation } from 'react-i18next'
import '../../i18n'
import { SnackbarProvider, withSnackbar } from 'notistack';
import EquipmentAdder from './EquipmentAdder'


const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  textField: {
    width: 200,
  },
  textFieldMiddle: {
    width: 250,
  },  
  textFieldLong: {
    width: 300,
  },
  fab2: {
    margin: theme.spacing.unit,
    display: 'flex',
    //marginRight: "auto" ,
    //justifyContent: 'flex-end',
   // position: 'absolute',
   // right: '0',
    float: 'right',
    background: '#cd0a1c'
  },
  fab3: {
    margin: theme.spacing.unit,
    display: 'flex',
    //marginRight: "auto" ,
    //justifyContent: 'flex-end',
   // position: 'absolute',
   // right: '0',
    float: 'right',
    background: '#28a745'
  },  
});

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#007BFF",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#ffffff",
  padding: grid,
  width: '100%'
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


class StoryboardUpdatePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
        name: this.props.storyboarddata.name,
        note: this.props.storyboarddata.note,
        shots: this.props.storyboarddata.shots,
        clip: this.props.storyboarddata.clip,
        media: this.props.storyboarddata.medias, 
        currentdescription: '',
        currentquestion: '', 
        currentanswer: '', 
        currentequipment: [], 
        emails: this.props.storyboarddata.emails, 
        AccordionsExpanded: false, 
        colorarray: [], 
        colorpicker: [], 
        unikey: 0, 
        soundData: [],  //possible sounds where user can choose from
        sound: {} //current defined sound for this clip / storyboard

    };

 //   this.toggle = this.toggle.bind(this);
  this.enterPressed = this.enterPressed.bind(this);
  this.emailcallback = this.emailcallback.bind(this)
  this.onDragEnd = this.onDragEnd.bind(this);


  }


  async getSoundsFromJoobsterDB() {

    var awaitFetch =   await fetch(settings.api_base_path + '/api/1.0/public/sounds', { method: 'GET' }) 
  
    let returnFetch = await awaitFetch.json()
  
     // console.log('fetchval ', returnFetch)
  
  
      return returnFetch
  
    }




 async componentDidMount(){
  const {t} = this.props;
  //console.log('data is: ', this.state.shots)
 
    let returnFetch = await this.getSoundsFromJoobsterDB()

    if (this.props.storyboarddata.clip && this.props.storyboarddata.clip.hasOwnProperty('colors') && this.props.storyboarddata.clip.colors){

      await this.initColorPicker()

    }    


    

    if (returnFetch.status === "ok") {

      this.setState({
        soundData: returnFetch.sounds, 
        loading: false
      })

      if (this.props.storyboarddata.clip && this.props.storyboarddata.clip.hasOwnProperty('soundId') && this.props.storyboarddata.clip.soundId){
        let s = await this.getSoundInfosFromID(this.props.storyboarddata.clip.soundId)
        console.log('sound is: ', s)
        this.setState({ sound: s })
      }      

      this.props.enqueueSnackbar(t('storyboardupdatepopup.musicsuccessfullyloaded'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

    } else {
      this.props.enqueueSnackbar(t('storyboardupdatepopup.musicloadingerror.text') , { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })
    
    }
    
    
  }
  async getSoundInfosFromID(id){
    const {t} = this.props; 
    let sound = this.state.soundData.find(f => f.id === id)
    return sound ? sound : {id: 'wrong', title: t('storyboardupdatepopup.soundnotavailable')}
  }



  async starCallback(starquestion){

      await this.setState({
        starquestions: starquestion
      })


  }



  delStoryboardShot(position){

    let localstbq = this.state.shots;
    localstbq.splice(position, 1)

  //  this.reformatShotIDs();

    this.setState({ shots: localstbq})
  }


generateJSON(){

  let localJSON = this.props.storyboarddata
  let settingsBody = {
    clip: {
    soundId: this.state.sound.id, 
    colors: this.getColorCodes()
    }
}  

    localJSON.clip = settingsBody.clip
    localJSON.name =  this.state.name
    localJSON.note = this.state.note,
    localJSON.shots = this.state.shots.map( shot => {

      let localshot = shot

      if ( shot.uuid ) {
        localshot.uuid = shot.uuid
      }
      localshot.description = shot.description
      localshot.question = shot.question
      localshot.answer = shot.answer
      localshot.equipment = shot.equipment




      return localshot;
    } )
    localJSON.emails = [...this.state.emails]


return JSON.stringify(localJSON)

}





getColorCodes(){
  return this.state.colorarray.map( c => c.color )
}

async updateStoryboardToJoobsterServer(e){

  //console.log('farbcodes sind: ', this.getColorCodes())

  //await this.setSettings()

  var json = this.generateJSON();
  const {t} = this.props;

 await fetch(settings.internalstorypath +  '/' + this.props.hash ,  {
		method: 'PUT',
		headers: {
		'Content-Type': 'application/json',
		//'Authorization' : 'Bearer ' + this.props.auth.sessionId
		},
		body: json
	}).then((res) => res.json())
	.then((data) => {
    console.log('storyboarddata posted', data)

    this.props.enqueueSnackbar(t('storyboardupdatepopup.update.success'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

	})
	.catch((err)=>{

    this.props.enqueueSnackbar(t('storyboardupdatepopup.update.errortext') + ' : ' + err, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

  });

  await this.props.storyboardcallback()



}

emailcallback(call){
  this.setState({
    emails: call
  })
}

currentChangeListener(name, position, e){

  let  findshot = this.state.shots[position]
 

  

  if (name =="question_arrayelem"){
    findshot.question = e.target.value
    this.setState({})
  }
  if (name =="description_arrayelem") {
    findshot.description = e.target.value
    this.setState({})
  }
  if (name =="answer_arrayelem") {
    findshot.answer = e.target.value
    this.setState({})
  }
  if (name =="question") this.setState({ currentquestion: e.target.value})
  if (name =="description") this.setState({ currentdescription: e.target.value})
  if (name =="answer") this.setState({ currentanswer: e.target.value})
  if (name =="name")
  this.setState({
    name : e.target.value
  })
  if (name =="hinweis")
  this.setState({
    note : e.target.value
  })
}

async move(array, from, to) {
  if( to === from ) return array;

  var target = array[from];
  var increment = to < from ? -1 : 1;

  for(var k = from; k != to; k += increment){
    array[k] = array[k + increment];
  }
  array[to] = target;
  return array;
}

 async newPosition(currentposition, direction){

  let from, to;
  from = currentposition;

  if (direction == "up" && currentposition != 0) to = currentposition -1;
  if ( direction == "down" && currentposition != this.state.shots.length-1) to = currentposition +1;

  if (currentposition == this.state.shots.length-1 && direction == "down"){
    from = currentposition;
    to = 0
  }

  if (currentposition == 0 && direction == "up"){
    from = currentposition;
    to = this.state.shots.length - 1;
  }


  let localstoryboardshots  = this.state.shots;

  await  this.move(localstoryboardshots, from, to)
  //this.reformatShotIDs();
  await this.setState({ shots: localstoryboardshots})

}

addStoryboardShot(stbq){
  let localstbq = [...this.state.shots, stbq];
  this.setState({
    shots: localstbq, 
    currentanswer: '', 
    currentquestion: '', 
    currentdescription: '',
    currentequipment: []
  })
}


enterPressed(e){
  if (e.key === 'Enter' && e.target.value.replace(/\s+/g,"") !== '') {
    this.addStoryboardShot({ description: this.state.currentdescription, question: this.state.currentquestion})
    }
}


statusIndicator(text, fiftypercentNumber, eightypercentNumber, hundretpercentNumber) {
const {t} = this.props; 
return (
<div>

 <Badge2 style={{background: '#28a745'}}> {t('storyboardupdatepopup.indicator10sec')} </Badge2> { ' / '}
<Badge2 style={{background: '#ffc107'}}> {t('storyboardupdatepopup.indicator16sec')} </Badge2> { ' / '}
 <Badge2 style={{background: '#DC3545'}}> {t('storyboardupdatepopup.indicator20sec')} </Badge2>

<hr/>
<ProgressBar>
    <ProgressBar animated striped variant="success"  now={text.length<fiftypercentNumber ? 50/fiftypercentNumber * text.length : 50} key={1} />

    <ProgressBar  animated striped variant="warning"  now={text.length>fiftypercentNumber-1 && text.length< eightypercentNumber ? 30/(eightypercentNumber-fiftypercentNumber) * (text.length - fiftypercentNumber-1) : text.length > (fiftypercentNumber-1) ? 30 : 0} key={2} />

    <ProgressBar animated striped variant="danger" now={text.length>(eightypercentNumber-1) && text.length<= hundretpercentNumber ? 20/(hundretpercentNumber-eightypercentNumber) * (text.length - eightypercentNumber-1) : text.length > (eightypercentNumber-1) ? 20 : 0} key={3} />
  </ProgressBar>
  </div>
  )

}






statusIndicatorVideo(fiftypercentNumber, eightypercentNumber, hundretpercentNumber) {

  // 10 Seconds are approximately 145Characters
  // 45 Seconds are 653 Characters
  // 60 Seconds are 870 Characters
  // 90 Seconds are 1305 Characters

  const {t} = this.props; 
 let shotanswercharacters = 0
 this.state.shots.map(shot => shotanswercharacters = shotanswercharacters + (shot.answer || "" ? shot.answer.length : 0) )



  return (
<div>
<p>{t('storyboardupdatepopup.secondslength')}: {Math.ceil( shotanswercharacters / 15 )}</p>

 <Badge2 style={{background: '#28a745'}}> {t('storyboardupdatepopup.indicatorfull30-45sec')} </Badge2> { ' / '}
<Badge2 style={{background: '#ffc107'}}> {t('storyboardupdatepopup.indicatorfull60-90sec')} </Badge2> { ' / '}
 <Badge2 style={{background: '#DC3545'}}> {t('storyboardupdatepopup.indicatorfullab90sec')} </Badge2>

<hr/>
<ProgressBar>
    <ProgressBar animated striped variant="success"  now={shotanswercharacters<fiftypercentNumber ? 50/fiftypercentNumber * shotanswercharacters : 50} key={1} />

    <ProgressBar  animated striped variant="warning"  now={shotanswercharacters>fiftypercentNumber-1 && shotanswercharacters< eightypercentNumber ? 30/(eightypercentNumber-fiftypercentNumber) * (shotanswercharacters - fiftypercentNumber-1) : shotanswercharacters > (fiftypercentNumber-1) ? 30 : 0} key={2} />

    <ProgressBar animated striped variant="danger" now={shotanswercharacters>(eightypercentNumber-1) && shotanswercharacters<= hundretpercentNumber ? 20/(hundretpercentNumber-eightypercentNumber) * (shotanswercharacters - eightypercentNumber-1) : shotanswercharacters > (eightypercentNumber-1) ? 20 : 0} key={3} />
  </ProgressBar>
  </div>
  )

}


setCurrentAnswer(type, position, placeholder){


  if (type === "creatorform"){
  this.setState({
    currentanswer: placeholder
  })

}
  else if (type === "statearray" ){

    let findshot = this.state.shots[position]
    findshot.answer = placeholder
    this.setState({ shots: this.state.shots})

  }
}

  mapToSeconds(text){
    const {t} = this.props; 

    let seconds = 0
    if (text){
    if (text.includes(t('general.placeholder'))){
      seconds = text.split(t('general.placeholder')).length - 1
    }else{
      seconds = Math.ceil( text.length / 15 )
    }
  }


    return seconds

  }


  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const shots = reorder(
      this.state.shots,
      result.source.index,
      result.destination.index
    );

    this.setState({
      shots
    });
  }

  setAllToExpanded(){
    this.state.shots.map( (f,i) => this.state.shots[i].expanded =  !this.state.AccordionsExpanded)
    this.setState({
      AccordionsExpanded: ! this.state.AccordionsExpanded
    })
  }

  setAccordionToExpanded(index){
    this.state.shots[index].expanded = !this.state.shots[index].expanded
    this.setState({})
  }


  removeColor(remidx){

    let cp = this.state.colorpicker; 
    let ca = this.state.colorarray
   // cp.splice(remidx,1)
   // console.log('Index: ' + remidx + ' color: ' + this.state.colorpicker[remidx].color)

  //  console.log(`before: ${remidx}`, cp.map(c => c.props.children.props.name))

    /*
   let newa = []
   newa.push(this.state.colorpicker[1])
   newa.push(this.state.colorpicker[0])
   */
   
   //cp.splice(remidx,1)
   //ca.splice(remidx,1)


  cp = cp.filter(f => f.key !== remidx)
  ca = ca.filter(f => f.key !== remidx)


 //  console.log('behind: ', cp.map(c => c.props.children.props.name))

//console.log('colorpicerk: ', cp.map(c=>c.key))
//console.log('colorarray: ', ca.map(c=>c.key + ' ' + c.color))



   this.setState({colorpicker: cp, colorarray: ca})
  }




  colorCallback(param){
// param is { key: ..., color: in hexcode }
    let mainkey = this.state.colorarray.find(c => c.key === param.key )
    mainkey.color = param.color

    let ca = this.state.colorarray.map( item => {return item.key === param.key ? mainkey : item} )

    this.setState({
      colorarray: ca
    })

    //console.log("colorarray is: ", this.state.colorarray)
  }

  async initColorPicker(){

    let cpicker = this.state.colorpicker;
    let colorarr = this.state.colorarray
    let ukey = this.state.unikey

    this.props.storyboarddata.clip.colors.map( async (col) => {
       ukey = ukey + 1;
      let picker = <div  ><SketchExample  hexval={col} mainkey={ukey} callback={(i) => this.colorCallback(i)}  name={"name-"+ukey}  /></div>

      cpicker.push(
        { key: ukey,
         cp: picker}
     )

     colorarr.push({
      key: ukey,
      color: col
    })


    })

    console.log('colorarr: ', colorarr)
    console.log('cpicker: ', cpicker)

    this.setState({
        colorpicker: cpicker,
        unikey: ukey,
        colorarray: colorarr
    })
  }



  addColor(){

    let cpicker = this.state.colorpicker;
    let ukey = this.state.unikey + 1;
    let picker = <div  ><SketchExample  mainkey={ukey} callback={(i) => this.colorCallback(i)}  name={"name-"+ukey}  /></div>

  
/*
    <ColorPicker 
    key={idx}
    name={"name-"+idx}
   defaultValue= { typeof this.state.colorpicker[idx] !== 'undefined' &&  this.state.colorpicker[idx].hasAttribute('color') ? console.log(this.state.colorpicker[idx].color) : null  } 
   onChange={ (c) =>    cpicker[idx].color = c } 
   />
*/
    

    cpicker.push(
       { key: ukey, 
        cp: picker}

    )

    let colorarr = this.state.colorarray
    colorarr.push({ 
      key: ukey, 
      color: "#F17013"
    })
    

    this.setState({
        colorpicker: cpicker, 
        unikey: ukey, 
        colorarray: colorarr
    })
  }

   openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  soundDefinitionCallback(c){
    
    console.log('in storyboardupdatepopup: ', c)
    this.setState({sound: c})
    
  }


  async setSettings() { //set colorcodes as well as music

      const {t} = this.props; 
      let jsonbody = {
          soundid: this.state.sound.id, 
          colors: this.getColorCodes()

      }

      await  fetch(settings.api_base_path + '/supergroup/' + this.props.sgid + '/storyboard/' + this.props.storyboarddata.id,  {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
        // 'Authorization' : 'Bearer ' + this.props.auth.sessionId
          },
          body: JSON.stringify(jsonbody)
        }).then((res) => res.json())
        .then((data) => { 
      
          console.log('data is: ', data)
  
          this.props.enqueueSnackbar(t('storyboardupdatepopup.setsettings.success'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })
		
        })
        .catch((err)=>{
          
          this.props.enqueueSnackbar(t('storyboardupdatepopup.setsettings.errortext') + ' : ' + err, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })
		
        });   
        
      
  
    }



  render() {

    const { classes, t } = this.props;
    const localthis = this;



    


    return (
      <div>

<Row>

<Fab onClick={() => this.props.storyboardcallback()} variant="extended" color="primary" aria-label="Delete" className={classes.fab2}>

<Reload />
{t('storyboardupdatepopup.loadstoryboardnew')}
</Fab>

<Fab variant="extended" style={{background: '#28a745'}} aria-label="Delete" className={classes.fab3}>

<PDFGenerator logo={this.props.storyboarddata.logo ? this.props.storyboarddata.logo : 'https://www.joobster.at/wp-content/uploads/2020/02/joobster_storyboard_glossy.png' } storyboarddata={this.props.storyboarddata}/>


</Fab>

<Fab variant="extended" style={{background: '#007bff', color: '#ffffff'}} aria-label="Delete" onClick={(e) => this.updateStoryboardToJoobsterServer(e)} className={classes.fab2}>

{t('storyboardupdatepopup.applychanges')}

</Fab>


</Row>



       <Card>
           <CardHeader>{t('storyboardupdatepopup.definitioncolorcodes')}
           <Fab  size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.addColor()}>
       <AddIcon  />
       </Fab>


           </CardHeader>
           <CardBody>

       {
        this.state.colorpicker && this.state.colorpicker.length > 0 && this.state.colorpicker.map((c, idx ) => 
        <div key={c.key}>

             <Row>
               <Col> { c.cp}  </Col> <Col>  
         <Fab  size="small" color="primary" aria-label="Remove" className={this.props.classes.fab} onClick={() => this.removeColor(c.key)}>
          <RemoveIcon />
          </Fab>                  
               
                </Col>
             </Row>
           
            </div>
        
          )
       }

</CardBody>
          </Card>        

        <Card>
          <CardHeader>
            
       <div style={ {display:"inline-flex", flexWrap: "wrap", gap: "10px" }}>
         <div>
            <UploadModal  
            link={settings.api_service_path + '/storyboard/' + this.props.hash +  '/' + this.props.languageCode}
            buttonLabel={t('uploadmodal.buttonlabel')} 
            mediasCallback={this.props.mediasCallback} 
            modalname={this.state.name} 
            sgid={this.props.sgid} 
            hash={this.props.storyboarddata.hash}            
            media={this.props.storyboarddata.medias}  
            id={this.props.storyboarddata.id}  />
            </div>
          
<div>
            <SoundDefinitionModal sound={this.state.sound} callback={(c) => this.soundDefinitionCallback(c)} buttonLabel={t('sounddefinitionmodal.musicselection')} />
            </div>


            </div>     

          </CardHeader>
          <CardBody></CardBody>
        </Card>


      <Card >
        
      <CardHeader>{t('storyboardupdatepopup.yourstoryboard')}</CardHeader>

          <CardBody>
            
          <TextField  id={"name"} className={classes.textField} variant="outlined" label={t('storyboardupdatepopup.name')} value={this.state.name} onChange={(e)=>this.currentChangeListener('name',null, e)} /> {'   '}
          <TextField id={"hinweis"} multiline className={classes.textFieldLong} variant="outlined" label={t('storyboardupdatepopup.note')} value={this.state.note} onChange={(e)=>this.currentChangeListener('hinweis',null, e)} />
          <EmailComponent className={classes.textFieldMiddle} input={this.state.emails} callback={(e) => this.emailcallback(e)}/>

<hr/>

            <Card body outline color="secondary">
              <CardHeader>{t('storyboardupdatepopup.addnewshot')}</CardHeader>
              <CardBody>
                <Container fluid>
                <Row>
                <CardHeader>{t('storyboardupdatepopup.shot')}-{this.state.shots.length + 1}{' '} <hr/> {this.mapToSeconds(this.state.currentanswer)} {t('storyboardupdatepopup.sec')}</CardHeader>

                  <Col xs lg="3"><TextField multiline className={classes.textField} variant="outlined" label={t('storyboardupdatepopup.description')} value={this.state.currentdescription} onChange={(e) => this.currentChangeListener('description', null, e)} /> </Col>

                  <Col  >

                      <Card>
                        <CardHeader>{t('storyboardupdatepopup.voicerecord')}: {this.mapToSeconds(this.state.currentanswer)} {t('storyboardupdatepopup.sec')}</CardHeader>
                        <CardBody>
                      <TextField  multiline className={classes.textField} variant="outlined" label={t('storyboardupdatepopup.question')} value={this.state.currentquestion} onChange={(e) => this.currentChangeListener('question', null, e)} /> /
                      <TextField  multiline className={classes.textFieldLong} variant="outlined" label={t('storyboardupdatepopup.answer')} value={this.state.currentanswer} onChange={(e) => this.currentChangeListener('answer', null, e)} />
                      </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>{t('storyboardupdatepopup.speechlessrecord')}</CardHeader>
                        <CardBody>

{
  /*
                      <FillShot callback={(x) => this.setCurrentAnswer('creatorform',null,x)} />
  */
}


                      <FillShot currentvalue={ this.state.currentanswer ? this.state.currentanswer.split("*" +t('general.placeholder') + "*").length - 1 : 0} callback={(x) => this.setCurrentAnswer('creatorform',null,x)} />


                      </CardBody>
                      </Card>
                      <Card>
                                                <CardHeader>{t('storyboard.uploadmodal.equipment')}</CardHeader>
                                                <CardBody>
                                                  <EquipmentAdder equipmentCallback={(e) => { this.setState({currentequipment: e})}} equipment={this.state.currentequipment} />
                                                </CardBody>
                                              </Card>                        
                      </Col>
                      <Col xs lg="1">
                    <Fab  size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => this.addStoryboardShot({ description: this.state.currentdescription, question: this.state.currentquestion, answer: this.state.currentanswer, equipment: this.state.currentequipment })}>
                      <AddIcon  />
                    </Fab>

                  </Col>
                </Row>
                </Container>
              </CardBody>
              <CardFooter>
              {this.statusIndicator(this.state.currentanswer || "", 145, 232, 290)}
              </CardFooter>
            </Card>

          <br />

            {this.state.shots && this.state.shots.length > 0 ?
            <Container>
              <Card color="primary" >
                <CardHeader> 
                  <Row>
                  <Col><p style={{ color: "#ffffff"}} ><h2>{this.state.name} / {this.state.note} </h2> </p>  
                  
                  
                  <div className="App" style={{ color: "#ffffff"}}>
      <FormControlLabel
        control={
          <Switch color="primary" checked={this.state.AccordionsExpanded} onChange={() => this.setAllToExpanded()} name="aufgeklappt" /> 
        }
        label={t('storyboardupdatepopup.unfold')}
      />
    </div>                  
                  
                  </Col> 
                  </Row>
                  </CardHeader>                
                <br />


                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {this.state.shots.map((elem, index) => (
                            <Draggable key={index} draggableId={ index.toString()} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >

                                    <Accordion  expanded={elem.expanded}  onChange={() => localthis.setAccordionToExpanded(index)} TransitionProps={{ unmountOnExit: true }}>  
                                 


                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}                                      
                                      aria-controls={`additional-actions${index}-control`}
                                      id={`additional-actions${index}-id`}
                                    >


                                      <FormControlLabel
                                        aria-label="Acknowledge"
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                          <div>
{/*                                           <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.setAccordionToExpanded(index)}>
                                            {elem.expanded ? <ExpandLess /> : <ExpandMore /> }                                            
                                            </Fab>                                                                                     
*/}
                                            <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.newPosition(index, 'up')}>
                                              <UpIcon />
                                            </Fab>
                                            <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.newPosition(index, 'down')}>
                                              <DownIcon />
                                            </Fab>

                                          </div>
                                        }


                                        label=""
                                      />

                                      <Typography className={classes.heading}>{t('storyboardupdatepopup.shot')}-{`${index + 1}`} | {this.mapToSeconds(this.state.shots[index].answer)} {t('storyboardupdatepopup.sec')} | {this.state.shots[index].description} </Typography>





                                    </AccordionSummary>



                                    <AccordionDetails>
                                      <Card>

                                        <CardBody>
                                          <Row>
                                            <CardHeader>{t('storyboardupdatepopup.shot')}-{`${index + 1}`}{' '} <hr /> {this.mapToSeconds(this.state.shots[index].answer)} {t('storyboardupdatepopup.sec')}</CardHeader>

                                            <Col xs lg="3"><TextField key={elem.uuid} multiline className={classes.textField} variant="outlined" label="Beschreibung" onChange={(e) => this.currentChangeListener('description_arrayelem', index, e)} value={this.state.shots[index].description} /></Col>
                                            <Col>
                                              <Card>
                                                <CardHeader>{t('storyboardupdatepopup.voicerecord')}: {this.mapToSeconds(this.state.shots[index].answer)} {t('storyboardupdatepopup.sec')}</CardHeader>
                                                <CardBody>
                                                  <TextField key={elem.uuid + "-" + elem.uuid} multiline className={classes.textField} variant="outlined" label="Frage" onChange={(e) => this.currentChangeListener('question_arrayelem', index, e)} value={this.state.shots[index].question} />
                                                  <TextField key={elem.uuid + "-" + elem.uuid + "-" + elem.uuid} multiline className={classes.textFieldLong} variant="outlined" label="Antwort" onChange={(e) => this.currentChangeListener('answer_arrayelem', index, e)} value={this.state.shots[index].answer} />
                                                </CardBody>
                                              </Card>
                                              <Card>
                                                <CardHeader>{t('storyboardupdatepopup.speechlessrecord')}</CardHeader>
                                                <CardBody>
                                                  {
                                                    /*
                                                  <FillShot callback={(x) => this.setCurrentAnswer('statearray', index, x)} />
                                                    */
                                                }

                                                  <FillShot currentvalue={ this.state.shots[index].answer ? this.state.shots[index].answer.split("*" +t('general.placeholder') + "*").length - 1 : 0} callback={(x) => this.setCurrentAnswer('statearray',index,x)} />

                                                </CardBody>
                                              </Card>
                                              <Card>
                                                <CardHeader>{t('storyboard.uploadmodal.equipment')}</CardHeader>
                                                <CardBody>
                                                  <EquipmentAdder equipmentCallback={(e) => {elem.equipment = e; console.log('equipment now', elem); this.setState({})}} equipment={ elem.equipment} />
                                                </CardBody>
                                                </Card>                                              
                                            </Col>
                                            <Col xs lg="1">

                                              <Fab size="small" color="primary" aria-label="Delete" className={classes.fab} onClick={() => localthis.delStoryboardShot(index)} >
                                                <DeleteIcon />
                                              </Fab>

                                              <Fab size="small" color="primary" aria-label="Save" className={classes.fab} onClick={(e) => this.updateStoryboardToJoobsterServer(e)} >
                                                <SaveButton />
                                              </Fab>


                                            </Col>
                                          </Row>


                                        </CardBody>
                                        <CardFooter>
                                          {this.statusIndicator(this.state.shots[index].answer || "", 145, 232, 290)}
                                        </CardFooter>


                                      </Card>
                                    </AccordionDetails>
                                  </Accordion>


                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

{
  /*
                  this.state.shots.map((elem, index) =>
                  <Accordion TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls={`additional-actions${index}-content`}
                    id={`additional-actions${index}-header`} 
                  >

                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <div>
                          <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.newPosition(index, 'up')}>
                            <UpIcon />
                          </Fab>
                          <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.newPosition(index, 'down')}>
                            <DownIcon />
                          </Fab>
                        </div>
                      }


                      label=""
                    />

                        <Typography className={classes.heading}>Shot-{`${index + 1}`} | {this.mapToSeconds(this.state.shots[index].answer)} Sek. | {this.state.shots[index].description} </Typography>
                     
                     


                
                  </AccordionSummary>
                  <AccordionDetails>
                  <Card>

                  <CardBody>
                    <Row>
          <CardHeader>Shot-{`${index + 1}`}{' '} <hr/> {this.mapToSeconds(this.state.shots[index].answer)} Sek.</CardHeader>

                      <Col xs  lg="3"><TextField key={elem.uuid}  multiline className={classes.textField} variant="outlined" label="Beschreibung" onChange={(e) => this.currentChangeListener('description_arrayelem', index, e)} value={this.state.shots[index].description} /></Col>
                      <Col>
                      <Card>
                <CardHeader>Sprachaufnahme | Dauer: {this.mapToSeconds(this.state.shots[index].answer)} Sek.</CardHeader>
                <CardBody>
                      <TextField  key={elem.uuid + "-"+elem.uuid} multiline className={classes.textField} variant="outlined" label="Frage" onChange={(e) => this.currentChangeListener('question_arrayelem', index, e)} value={this.state.shots[index].question} />
                      <TextField  key={elem.uuid + "-"+elem.uuid + "-"+elem.uuid} multiline className={classes.textFieldLong} variant="outlined" label="Antwort" onChange={(e) => this.currentChangeListener('answer_arrayelem', index, e)} value={this.state.shots[index].answer} />
                      </CardBody>
                      </Card>
                      <Card>
                <CardHeader>Sprachlose Aufname - In Sekunden</CardHeader>
                <CardBody>
                      <FillShot callback={(x) => this.setCurrentAnswer('statearray',index,x)} />
                      </CardBody>
                      </Card>
                      </Col>
                      <Col xs lg="1">

                        <Fab size="small"  color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.delStoryboardShot(index)} >
                          <DeleteIcon />
                        </Fab>
                      </Col>
                    </Row>


                  </CardBody>
                  <CardFooter>
      {this.statusIndicator(this.state.shots[index].answer || "", 145, 232, 290)}
      </CardFooter>


                </Card>
                </AccordionDetails>
                    </Accordion>                

                  )
                */  
                }
              </Card> </Container> : null
 
            }          


</CardBody>
          <CardFooter style={{ background: 'lightblue' }}>
            {
              this.statusIndicatorVideo(653,1305,2000)
            }
          </CardFooter>
        </Card>



              <Row>

<Fab onClick={() => this.props.storyboardcallback()} variant="extended" color="primary" aria-label="Delete" className={classes.fab2}>

<Reload />
{t('storyboardupdatepopup.loadstoryboardnew')}
</Fab>

<Fab variant="extended" style={{background: '#28a745'}} aria-label="Delete" className={classes.fab3}>

<PDFGenerator logo={this.props.storyboarddata.logo ? this.props.storyboarddata.logo : 'https://www.joobster.at/wp-content/uploads/2020/02/joobster_storyboard_glossy.png' } storyboarddata={this.props.storyboarddata}/>


</Fab>

<Fab variant="extended" style={{background: '#007bff', color: '#ffffff'}} aria-label="Delete" onClick={(e) => this.updateStoryboardToJoobsterServer(e)} className={classes.fab2}>

{t('storyboardupdatepopup.applychanges')}

</Fab>


</Row>          

      </div>
    );
  }
}



export default withSnackbar(withTranslation()(withStyles(styles)(StoryboardUpdatePopup)));


