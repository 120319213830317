
    import React from 'react';
    import ChipInput from 'material-ui-chip-input'
    import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
    import Swal from 'sweetalert2';
    import { withTranslation } from 'react-i18next'
    import '../../i18n'
class EmailComponent extends React.Component{
  constructor (props) {
    super(props)
    this.state = {
      chips: this.props.input || []
    }
  }



  componentDidMount(){
    console.log('In Emailcomponent: ', this.props.input)
    this.setState({
      chips: this.props.input
    })
  }

/*
 async  postTagsTojoobster(){

    var postingJSON = {
      tags: this.state.chips
    }

    var changedClip = this.props.clip;
    changedClip.tags = this.state.chips;

   // this.props.updateClipLocalStore({clip:  changedClip})

     fetch(settings.api_base_path + settings.api_admin_path + '/group/' + this.props.gid + '/clip/' + this.props.clip.id + '/tag', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.auth.sessionId
        },
        body: JSON.stringify(postingJSON),
    }).then((res) => res.json())
        .then((data) => data);



}
*/


validateEmail(email)
{

    if(/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(email)) {
       return true;

    }else return false

}

  async handleAdd (...chips) {

  const {t} = this.props; 
  let emailtest = true;

  chips.map( chip => {
    if (!this.validateEmail(chip)){
      emailtest = false
      Swal.fire({
        title: t('emailcomponent.titleerror'),
        position: 'center',
        text: t('emailcomponent.wrongemail'),
        type: 'error',
        animation: true,
        customClass: {
          popup: 'animated tada'
        },
        showConfirmButton: false,
        timer: 1000
        //confirmButtonText: 'Cool'
      })
    }
  })
  if (emailtest ){
   await this.setState({
      chips: [...this.state.chips, ...chips]
    })

  this.props.callback(this.state.chips)
  }
  }

async  handleDelete (deletedChip) {
  await  this.setState({
      chips: this.state.chips.filter((c) => c != deletedChip)
    })
    this.props.callback(this.state.chips)
  }

  render () {
    const {t} = this.props; 
    return (
      <MuiThemeProvider>
      <ChipInput

        {...this.props}
        label={t('emailcomponent.email')}
        //defaultValue={this.state.chips}
        value={this.state.chips}
        variant="outlined"
        onPaste={(event) => {
          const clipboardText = event.clipboardData.getData('Text')

          event.preventDefault()

          this.handleAdd(...clipboardText.split(' ').filter((t) => t.length > 0 && t.includes('@')))

          if (this.props.onPaste) {
            this.props.onPaste(event)

          }
        }}
        onAdd={(chip) => this.handleAdd(chip)}

        onDelete={(deletedChip) => this.handleDelete(deletedChip)}


        />
      </MuiThemeProvider>
    )
  }
}


export default withTranslation()(EmailComponent);



