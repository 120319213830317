import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

class SketchExample extends React.Component {

  constructor(props){
  super(props)

  let hexval = null
  if ( this.props.hexval )
     hexval = this.hexToRGB(this.props.hexval, this.props.alpha ? this.props.alpha : 1)

  this.state = {
    displayColorPicker: false,
    color: {
      r: this.props.hexval ? hexval[0] :'241',
      g: this.props.hexval ? hexval[1] :'112',
      b: this.props.hexval ? hexval[2] :'19',
      a: this.props.hexval ? hexval[3] :'1',
    },
  }
}





  componentDidMount(){

    if ( this.props.hexval ){

    let hexval = this.hexToRGB(this.props.hexval, this.props.alpha ? this.props.alpha : 1)

    this.setState({
      color:{
        r: hexval[0],
        g: hexval[1],
        b: hexval[2],
        a: hexval.length === 3 ? 1 : hexval[4],
      }
    })
  }

  }


  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return [r,g,b,alpha];
    } else {
        return [r,g,b]
    }
}




  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb })
    console.log('color choosen: ', color.hex)
    this.props.callback({key: this.props.mainkey, color: color.hex})
  };

  render() {

    let styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div >
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker key={this.props.mainkey} color={ this.state.color } onChange={ this.handleChange } />
        </div> : null }

      </div>
    )
  }
}

export default SketchExample
