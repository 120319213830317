import React from 'react';
import {  Form, FormGroup, FormText, Alert, View, InputGroup, InputGroupText, InputGroupAddon,  Input,  Fade, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-activity/lib/Dots/Dots.css';
import "../../../node_modules/video-react/dist/video-react.css";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { Player, BigPlayButton } from 'video-react';
import VideoCard from './VideoCard'
import { withTranslation } from 'react-i18next'
import '../../i18n'



const styles = theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
});

class SingleVideoPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

  this.toggle = this.toggle.bind(this);

  }


  toggle(){

    this.setState({ modal: !this.state.modal})
  }




  render() {

    const {t} = this.props; 
    return (
      <div>
        
        <VideoCard callback={this.toggle} image={"https://admin.joobster.at/cloud/joobster-0eut76ea/oLvbJMqa/lo.jpg"} maintext={t('singlevideopopup.explanation')} subtext={t('singlevideopopup.video')} />

        <Modal size='lg' isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>



          <ModalHeader toggle={this.toggle}>{t('mainsite.explainvideo')} {this.props.id}</ModalHeader>
          <ModalBody>
           
          <Player   
          autoPlay
poster={"https://admin.joobster.at/cloud/joobster-0eut76ea/oLvbJMqa/lo.jpg"} 
src={"https://admin.joobster.at/cloud/joobster-0eut76ea/oLvbJMqa/hi.mp4"} >
                    <BigPlayButton position="center" />
                    
              </Player> 
           <div>

    </div>

	          </ModalBody>
          <ModalFooter>


			<Button  color="primary" onClick={() => this.toggle()}>{t('general.closetext')}</Button>



          </ModalFooter>



        </Modal>


      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(SingleVideoPopup));

