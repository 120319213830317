import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';

//import registerServiceWorker from './registerServiceWorker';

//import { Provider } from 'react-redux';
//import store from './store';

//<Provider store={store}>

ReactDOM.render(
  (
    // Provide our store to the application
    <SnackbarProvider maxSnack={3}>
      <App />
      </SnackbarProvider>
   
  ),
  document.getElementById('root'));
//registerServiceWorker();
