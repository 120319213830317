import React from 'react';
import { CardHeader, Card, CardBody, Row,Col, Form, FormGroup, FormText, Alert, View, InputGroup, InputGroupText, 
  InputGroupAddon,  Input,  Fade, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-activity/lib/Dots/Dots.css';
import "../../../node_modules/video-react/dist/video-react.css";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next'
import '../../i18n'
import settings from '../../settings'
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, customFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Rowcheckbox from './Rowcheckbox'
import { Player, BigPlayButton } from 'video-react';
import TrashIcon from '@material-ui/icons/Delete'
import Reload from '@material-ui/icons/Cached'
import { SnackbarProvider, withSnackbar } from 'notistack';
import AudioPlayer from 'material-ui-audio-player';
import JImageEditor from './JImageEditor'

import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "./css/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import "./css/filepond-plugin-image-preview.css";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);



const styles = theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
});

class UploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedRows: [], // holds current selected rows, checked = true
      shotfiles: null, // filepond
      singleshotfile: null, //filepond for ImageEditor-Upload = just a single file
      logofiles: null, //filepond
      audiofiles: null,  //filepond
      media : this.props.media,
      source: "index.html",
      options: {
        type: "local"
      },
      media : this.props.media

    };

		this.setSelectedRow = this.setSelectedRow.bind(this);

  this.toggle = this.toggle.bind(this);

  }

/*
static getDerivedStateFromProps(props, state){

  if (JSON.stringify(props.media) !== JSON.stringify(state.media)){

    console.log('got new data: ', props.media)

  return {
    media: props.media
  }
}

  return null;

}
*/

handleInit() {
  console.log("FilePond instance has initialised", this.pond);

  
}


async componentDidUpdate(){

  //if (this.state.media && this.state.media.length > 0)
  if (JSON.stringify(this.props.media) !== JSON.stringify(this.state.media)){
    await this.setState({ media: this.props.media})
    if (this.props.media && this.props.media.length > 0  )
    await this.renameMediaType()
} 



}


  setSelectedRow(rowid, dataentry, checkedval) {

		var rowarray = this.state.selectedRows;
		var index = this.getIndexOfObjectByKey(rowarray, 'id', rowid);

		if (index == null) {
			rowarray.push({ id: rowid, data: dataentry, checked: checkedval });
		} else {
			rowarray.splice(index, 1);
		}

		this.setState({ selectedRows: rowarray });
		console.log('Anzahl an selektierten Reihen: ', this.state.selectedRows)
		return null;
	}


	getIndexOfObjectByKey(array, key, value) {
		for (var i = 0; i < array.length; i++) {
			if (array[i][key] === value) {
				return i;
			}
		}
		return null;
	}

  async componentDidMount(){
    if ( this.state.media && this.state.media.length > 0)
      await this.renameMediaType()
  }


  async toggle(){

/*
    if (!this.state.modal){

 
    

    }
*/

  //  console.log('media is: ', this.state.media)

    this.setState({ modal: !this.state.modal})
  }


  async renameMediaType(){
    await this.state.media.map( mediaelem => {

      let fileExt = mediaelem.media.split('.').pop();
      fileExt = fileExt.toLowerCase()

      let Suffix = mediaelem.mediaType === "logo" ? "Logo" : mediaelem.mediaType === "shot" ? "Aufnahme" : ""

       if (["jpeg", "jpg", "png"].includes(fileExt)){
         mediaelem.mediaType = "Bild" + "-" + Suffix
       }else if (["mov", "mp4"].includes(fileExt)){
         mediaelem.mediaType = "Video" + "-" + "Aufnahme"
       }else if (["mp3"].includes(fileExt)){
         mediaelem.mediaType = "Audio"
       }
   })
   this.setState({})
  }


  async getMediaFromStoryboard() {

    const {t} = this.props;

    await fetch(settings.api_base_path + '/worker/clip/next/' + this.props.id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.auth.sessionId
      },
    }).then((res) => res.json())
      .then((data) => {

        console.log('data is: ', data)

        if (data.code == "authorizationRequired") {
          this.props.authSignout()
          Swal.fire({
            title: t('microlearing.reporting.loggedout'),
            position: 'center',
            text: t('microlearing.reporting.loggedouttext'),
            animation: true,
            customClass: {
              popup: 'animated data'
            },
            type: 'error',
            showConfirmButton: true,
            //timer: 3000
            confirmButtonText: t('microlearing.reporting.logoutbutton')
          })
          return null
        }


       // var localstoryboards = data.storyboards;

        this.setState({
          storyboards: data.storyboards
        })

      })
      .catch((err) => {

          this.props.authSignout()
          console.log('auth is: ', this.props.auth)
          Swal.fire({
            title: t('microlearing.reporting.loggedout'),
            position: 'center',
            text: t('microlearing.reporting.loggedouttext'),
            animation: true,
            customClass: {
              popup: 'animated data'
            },
            type: 'error',
            showConfirmButton: true,
            //timer: 3000
            confirmButtonText: t('microlearing.reporting.logoutbutton')
          })
      });


  }

  async deleteSelectedRows(id){
      const {t} = this.props;
      const ids = id ? [id] : this.state.selectedRows.map( row => row.id)


      const jsonbody = {
        mediaIds : ids
      }
      console.log('ids: ', JSON.stringify(jsonbody))



     await  fetch('/storyboard/' + this.props.hash +'/media' ,  {
         method: 'DELETE',
         /*headers: {
         'Content-Type': 'application/json',
         'Authorization' : 'Bearer ' + this.props.auth.sessionId
         },
         */
         body: JSON.stringify(jsonbody)
       }).then((res) => res.json())
       .then((data) => {


        console.log('response is:', data )
        this.props.mediasCallback()

        this.setState({selectedRows : []})

         this.props.enqueueSnackbar(t('storyboard.uploadmodal.deletesuccess'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

       })
       .catch((err)=>{

         this.props.enqueueSnackbar(t('storyboard.uploadmodal.deleteerror') + ' : ' + err, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })
       });



     }


     progress(){
       console.log('progress is: uploading')
     }

  render() {

    const {t} = this.props;
    const localthis = this;






    const columns = [
      {
        dataField: 'mediaType',
        text: t('storyboard.uploadmodal.column.mediatype'),
        formatter: mediaFormatter,
        filter: textFilter({
          placeholder: t('storyboard.uploadmodal.column.mediatypesearch')
        }),
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
      },
      {
        dataField: 'media',
        text: t('storyboard.uploadmodal.column.media'),
        formatter: nameFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '30%', textAlign: 'left' };
        },
      },
      {
        dataField: 'id',
        text: t('storyboard.uploadmodal.column.select'),
        formatter: portalFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'left' };
        },
        formatExtraData: [this.state.selectedRows, this.setSelectedRow]

      }


    ];



    const selectRow = {
      mode: 'checkbox',
      classes: 'selection-row',
      //headerFormatter: selectFormatter,
      onSelect: (row, isSelect, rowIndex, e) => {
        //	if (isSelect) this.setSelectedRow(row.id, row, true);
        console.log('clicked row is : ', row);
        this.setState({
          choosenclips: [row]
        })
      },
      onSelectAll: (isSelect, rows, e) => {
        console.log(isSelect);
        console.log(rows);
        console.log(e);
      }

    };

    function mediaFormatter (cell, row, rowIndex, formatExtraData) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {row.mediaType}

        </div>
      );
    }
    function nameFormatter(cell, row, rowIndex, formatExtraData){

      var fileExt = row.media.split('.').pop();
      fileExt = fileExt.toLowerCase()

      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {
          (['mov', 'mp4']).includes(fileExt) ?
          <Player playsInline  preload={"none"} poster={row.thumb} src={row.media} >
          <BigPlayButton position="center" />
      </Player>
            :  (['jpg', 'png', 'jpeg']).includes(fileExt) ?
                <img src={row.media} />

          : (['mp3']).includes(fileExt) ?
          <AudioPlayer
          preload="auto"
                        src={row.media} 
                        elevation={1}
                        width="100%"
                        variation="primary"
                        spacing={1}
                        download={true}                        
                        order="standart"                        
                        loop={true}
                        
                        
                        />
          : null
          }
        </div>
      );
    }

		function portalFormatter(cell, row, rowIndex, formatExtraData) {
      var fileExt = row.media.split('.').pop();
      fileExt = fileExt.toLowerCase()      
			return (
				<div>
          <Row>
            <Col >
					<Rowcheckbox data={row} type="checkbox" variableTransfer={localthis.setSelectedRow} isIn={localthis.getIndexOfObjectByKey(localthis.state.selectedRows, 'id', row.id) != null} clipdata={localthis.state.selectedRows} sgid={localthis.props.sgid} />
          </Col>

          <Col  >

            <Button onClick={() => localthis.deleteSelectedRows(row.id)}>
              <TrashIcon />
            </Button>

            {
            (['jpg', 'png', 'jpeg']).includes(fileExt) ?

            <hr/>
            : null
    }

{
            (['jpg', 'png', 'jpeg']).includes(fileExt) ?
                <JImageEditor tableelement={true} save={true} link={row.media}
                callback={ (data, decision) =>{
                  if (decision === false) {
                    localthis.deleteSelectedRows(row.id);
                    localthis.pondshotsingle.addFile(data);
                    localthis.pondshotsingle.processFiles();
                  }else
                  {
                    localthis.pondshotsingle.addFile(data);
                    localthis.pondshotsingle.processFiles();
                  }
                }} />
                : null
}


          </Col>
          </Row>
				</div>
			);
		}

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log(e + ' ' + row + ' ' + rowIndex);
      }
    }

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
       &nbsp; {t('storyboard.uploadmodal.mediafile')} {from} - {to} {t('storyboard.uploadmodal.mediafilefrom')} {size}
        </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
			firstPageText: t('general.tablefirstpagetext'),
			prePageText: t('general.tableprepagetext'),
			nextPageText: t('general.tablenextpagetext'),
			lastPageText: t('general.tablelastpagetext'),
			nextPageTitle: t('general.tablenextpagetitle'),
			prePageTitle: t('"general.tableprepagetitle'),
			firstPageTitle: t('general.tablefirstpagetitle'),
			lastPageTitle: t('general.tablelastpagetitle'),
      showTotal: true,
      paginationTotalRenderer:  customTotal,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: this.state.media ? this.state.media.length : 0
      }] // A numeric array is also available. the purpose of above example is custom the text
    };




    return (
      <div>

        <Button color="primary" onClick={this.toggle}>{this.props.buttonLabel}</Button>

        <Modal disableAutoFocus size='xl' isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>



          <ModalHeader toggle={this.toggle}>{t('clips.videocreationmodal.upload') + ' '} {this.props.modalname}</ModalHeader>
          <ModalBody>

          <div style={{ display: '' }}>
                    <form id="singleFileUploadForImageEditor" className="form-horizontal" >
                      <div className="App">
                        <FilePond
                          name="uploaded_media"
                          allowBrowse={false}
                          credits={false}
                          allowDrop={false}
                          labelIdle={t('storyboard.uploadmodal.filepond.singlefileupload.labelidle')}
                          labelInvalidField={t('storyboard.uploadmodal.filepond.invalidfield')}
                          labelFileWaitingForSize={t('storyboard.uploadmodal.filepond.filewaitingforsize')}
                          labelFileSizeNotAvailable={t('storyboard.uploadmodal.filepond.filesizenotavailable')}
                          labelFileProcessing={t('storyboard.uploadmodal.filepond.fileprocessing')}
                          labelTapToCancel={t('storyboard.uploadmodal.filepond.taptocancel')}
                          labelTapToRetry={t('storyboard.uploadmodal.filepond.taptoretry')}
                          labelFileProcessingAborted={t('"storyboard.uploadmodal.filepond.fileprocessingaborted')}
                          labelFileLoading={t('storyboard.uploadmodal.filepond.fileloading')}
                          labelFileLoadError={t('storyboard.uploadmodal.filepond.fileloaderror')}
                          labelFileProcessingComplete={t('storyboard.uploadmodal.filepond.fileprocessingcomplete')}
                          labelFileProcessingError={t('storyboard.uploadmodal.filepond.fileprocessingerror')}
                          labelFileProcessingRevertError={t('storyboard.uploadmodal.filepond.fileprocessingreverterror')}
                          labelFileRemoveError={t('storyboard.uploadmodal.filepond.fileremoveerror')}
                          labelTapToUndo={t('storyboard.uploadmodal.filepond.taptoundo')}
                          labelButtonRemoveItem={t('storyboard.uploadmodal.filepond.buttonremoveitem')}
                          labelButtonAbortItemLoad={t('storyboard.uploadmodal.filepond.buttonabortitemload')}
                          labelButtonRetryItemLoad={t('storyboard.uploadmodal.filepond.buttonretryitemload')}
                          labelButtonAbortItemProcessing={t('storyboard.uploadmodal.filepond.buttonabortitemprocessing')}
                          labelButtonUndoItemProcessing={t('storyboard.uploadmodal.filepond.buttonundoitemprocessing')}
                          labelButtonRetryItemProcessing={t('storyboard.uploadmodal.filepond.buttonretryitemprocessing')}
                          labelButtonProcessItem={t('storyboard.uploadmodal.filepond.buttonprocessitem')}

                          ref={ref => (this.pondshotsingle = ref)}
                          files={this.state.singleshotfile}
                          allowMultiple={true}
                          instantUpload={true}
                          allowReorder={true}
                          allowReplace={false}
                          allowRevert={false}
                          maxFileSize={100000000}
                          allowFileTypeValidation={true}
                          allowFileSizeValidation={true}
                          acceptedFileTypes={['video/mp4', 'video/quicktime', 'image/png', 'image/jpeg', 'image/jpg']}
                          maxParallelUploads={1}
                          onprocessfiles={() => { this.setState({ singleshotfile: null }); this.props.mediasCallback() }}
                          server={{
                            url: '/resource/storyboard',

                            process: {

                              headers: {
                                'X-File-Upload': JSON.stringify({ referenceId: this.props.hash, resourceMedia: 'video', resourceType: 'shot' })

                              }
                            }
                          }}
                          oninit={() => this.handleInit()}
                          onupdatefiles={fileItems => {
                            // Set currently active file objects to this.state
                            this.setState({
                              singleshotfile: fileItems.map(fileItem => fileItem.file)
                            });

                          }}
                        />
                      </div>
                    </form>
                  </div>



            <div style={{ display: 'inline-flex', gap: "10px" }}>


              <JImageEditor tableelement={false} link={null} text={t('clips.jimageeditor.buttontext')} callback={(data, decision) => {


                if (decision === false) {
                  this.deleteSelectedRows(data);
                  this.pondshotsingle.addFile(data);
                  this.pondshotsingle.processFiles();
                } else {
                  this.pondshotsingle.addFile(data);
                  this.pondshotsingle.processFiles();

                }

              }
              } />

              {
                this.state.media && this.state.media.length > 0 ?

                  <Button disabled={!(this.state.selectedRows && this.state.selectedRows.length > 0)} onClick={() => this.deleteSelectedRows()}>
                    {t('storyboard.uploadmodal.deletemultipleselection')} {(this.state.selectedRows.length)}  <TrashIcon />
                  </Button>
                  : null
              }
              {' '}
              {

                <Button onClick={() => this.props.mediasCallback()}>
                  {t('storyboard.uploadmodal.reloaddata')} <Reload />
                </Button>


              }

            </div>


            {
              this.state.media && this.state.media.length > 0 ?
                <div>

                  <br />

                  <BootstrapTable keyField='id'
                    //ref='table'
                    data={this.state.media}
                    filter={filterFactory()}
                    columns={columns}
                    rowEvents={rowEvents}
                    pagination={paginationFactory(options)}
                  />


                  <Button disabled={!(this.state.selectedRows && this.state.selectedRows.length > 0)} onClick={() => this.deleteSelectedRows()}>
                    {t('storyboard.uploadmodal.deletemultipleselection')} {(this.state.selectedRows.length)} <TrashIcon />
                  </Button>
                  {' '}
                  <Button onClick={() => this.props.mediasCallback()}>
                    {t('storyboard.uploadmodal.reloaddata')} <Reload />
                  </Button>
                </div>
                : null
            }




<br/>

            <Card>

              <CardHeader>
                <h2>{t('storyboard.uploadmodal.shotupload')}</h2>
                <p>{t('storyboard.uploadmodal.shotuploadinfo')}</p>

              </CardHeader>
              <CardBody>


                <div className="wrapper">
                  <h3>{t('storyboard.uploadmodal.uploadshots')} ({t('storyboard.uploadmodal.formats')}: mov, mp4, jpg, png)</h3>
                  <form id="enableFormShotFiles" className="form-horizontal" >
                    <div className="App">
                      <FilePond
                        name="uploaded_media"
                        labelIdle={t('storyboard.uploadmodal.filepond.idle')}
                        labelInvalidField={t('storyboard.uploadmodal.filepond.invalidfield')}
                        labelFileWaitingForSize={t('storyboard.uploadmodal.filepond.filewaitingforsize')}
                        labelFileSizeNotAvailable={t('storyboard.uploadmodal.filepond.filesizenotavailable')}
                        labelFileProcessing={t('storyboard.uploadmodal.filepond.fileprocessing')}
                        labelTapToCancel={t('storyboard.uploadmodal.filepond.taptocancel')}
                        labelTapToRetry={t('storyboard.uploadmodal.filepond.taptoretry')}
                        labelFileProcessingAborted={t('"storyboard.uploadmodal.filepond.fileprocessingaborted')}
                        labelFileLoading={t('storyboard.uploadmodal.filepond.fileloading')}
                        labelFileLoadError={t('storyboard.uploadmodal.filepond.fileloaderror')}
                        labelFileProcessingComplete={t('storyboard.uploadmodal.filepond.fileprocessingcomplete')}
                        labelFileProcessingError={t('storyboard.uploadmodal.filepond.fileprocessingerror')}
                        labelFileProcessingRevertError={t('storyboard.uploadmodal.filepond.fileprocessingreverterror')}
                        labelFileRemoveError={t('storyboard.uploadmodal.filepond.fileremoveerror')}
                        labelTapToUndo={t('storyboard.uploadmodal.filepond.taptoundo')}
                        labelButtonRemoveItem={t('storyboard.uploadmodal.filepond.buttonremoveitem')}
                        labelButtonAbortItemLoad={t('storyboard.uploadmodal.filepond.buttonabortitemload')}
                        labelButtonRetryItemLoad={t('storyboard.uploadmodal.filepond.buttonretryitemload')}
                        labelButtonAbortItemProcessing={t('storyboard.uploadmodal.filepond.buttonabortitemprocessing')}
                        labelButtonUndoItemProcessing={t('storyboard.uploadmodal.filepond.buttonundoitemprocessing')}
                        labelButtonRetryItemProcessing={t('storyboard.uploadmodal.filepond.buttonretryitemprocessing')}
                        labelButtonProcessItem={t('storyboard.uploadmodal.filepond.buttonprocessitem')}

                        ref={ref => (this.pondshot = ref)}
                        files={this.state.shotfiles}
                        allowMultiple={true}
                        instantUpload={false}
                        allowReorder={true}
                        allowReplace={false}
                        allowRevert={false}
                        credits={false}
                        maxFileSize={100000000}
                        allowFileTypeValidation={true}
                        allowFileSizeValidation={true}
                        acceptedFileTypes={['video/mp4', 'video/quicktime', 'image/png', 'image/jpeg', 'image/jpg']}
                        maxParallelUploads={1}
                        onprocessfiles={() => { this.setState({ shotfiles: null }); this.props.mediasCallback() }}
                        server={{
                          url: '/resource/storyboard',

                          process: {

                            headers: {
                              'X-File-Upload': JSON.stringify({ referenceId: this.props.hash, resourceMedia: 'video', resourceType: 'shot' })

                            }
                          }
                        }}
                        oninit={() => this.handleInit()}
                        onupdatefiles={fileItems => {
                          // Set currently active file objects to this.state
                          this.setState({
                            shotfiles: fileItems.map(fileItem => fileItem.file)
                          });
                        }}
                      />
                    </div>
                  </form>

                  <h3>{t('storyboard.uploadmodal.uploadlogo')} ({t('storyboard.uploadmodal.formats')}: jpg, png)</h3>
                  <form id="enableFormLogofiles" className="form-horizontal">
                    <div className="App">
                      <FilePond
                        name="uploaded_media"
                        labelIdle={t('storyboard.uploadmodal.filepond.idle')}
                        labelInvalidField={t('storyboard.uploadmodal.filepond.invalidfield')}
                        labelFileWaitingForSize={t('storyboard.uploadmodal.filepond.filewaitingforsize')}
                        labelFileSizeNotAvailable={t('storyboard.uploadmodal.filepond.filesizenotavailable')}
                        labelFileProcessing={t('storyboard.uploadmodal.filepond.fileprocessing')}
                        labelTapToCancel={t('storyboard.uploadmodal.filepond.taptocancel')}
                        labelTapToRetry={t('storyboard.uploadmodal.filepond.taptoretry')}
                        labelFileProcessingAborted={t('"storyboard.uploadmodal.filepond.fileprocessingaborted')}
                        labelFileLoading={t('storyboard.uploadmodal.filepond.fileloading')}
                        labelFileLoadError={t('storyboard.uploadmodal.filepond.fileloaderror')}
                        labelFileProcessingComplete={t('storyboard.uploadmodal.filepond.fileprocessingcomplete')}
                        labelFileProcessingError={t('storyboard.uploadmodal.filepond.fileprocessingerror')}
                        labelFileProcessingRevertError={t('storyboard.uploadmodal.filepond.fileprocessingreverterror')}
                        labelFileRemoveError={t('storyboard.uploadmodal.filepond.fileremoveerror')}
                        labelTapToUndo={t('storyboard.uploadmodal.filepond.taptoundo')}
                        labelButtonRemoveItem={t('storyboard.uploadmodal.filepond.buttonremoveitem')}
                        labelButtonAbortItemLoad={t('storyboard.uploadmodal.filepond.buttonabortitemload')}
                        labelButtonRetryItemLoad={t('storyboard.uploadmodal.filepond.buttonretryitemload')}
                        labelButtonAbortItemProcessing={t('storyboard.uploadmodal.filepond.buttonabortitemprocessing')}
                        labelButtonUndoItemProcessing={t('storyboard.uploadmodal.filepond.buttonundoitemprocessing')}
                        labelButtonRetryItemProcessing={t('storyboard.uploadmodal.filepond.buttonretryitemprocessing')}
                        labelButtonProcessItem={t('storyboard.uploadmodal.filepond.buttonprocessitem')}

                        ref={ref => (this.pondlogo = ref)}
                        files={this.state.logofiles}
                        allowMultiple={false}
                        instantUpload={false}
                        allowImageCrop={true}
                        allowReorder={false}
                        allowReplace={false}
                        allowRevert={false}
                        credits={false}
                        maxFileSize={10000000}
                        maxFiles={1}
                        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                        maxParallelUploads={1}
                        onprocessfiles={() => { this.setState({ logofiles: null }); this.props.mediasCallback() }}
                        server={{
                          url: '/resource/storyboard',
                          process: {
                            headers: {
                              'X-File-Upload': JSON.stringify({ referenceId: this.props.hash, resourceMedia: 'image', resourceType: 'logo' })
                            }
                          }
                        }}
                        oninit={() => this.handleInit()}
                        onupdatefiles={fileItems => {
                          // Set currently active file objects to this.state
                          this.setState({
                            logofiles: fileItems.map(fileItem => fileItem.file)
                          });
                        }}
                      />
                    </div>

                  </form>

                  <h3>{t('storyboard.uploadmodal.uploadaudio')} ({t('storyboard.uploadmodal.formats')}: mp3)</h3>
                  <form id="enableFormAudiofiles" className="form-horizontal">
                    <div className="App">
                      <FilePond
                        name="uploaded_media"
                        labelIdle={t('storyboard.uploadmodal.filepond.idle')}
                        labelInvalidField={t('storyboard.uploadmodal.filepond.invalidfield')}
                        labelFileWaitingForSize={t('storyboard.uploadmodal.filepond.filewaitingforsize')}
                        labelFileSizeNotAvailable={t('storyboard.uploadmodal.filepond.filesizenotavailable')}
                        labelFileProcessing={t('storyboard.uploadmodal.filepond.fileprocessing')}
                        labelTapToCancel={t('storyboard.uploadmodal.filepond.taptocancel')}
                        labelTapToRetry={t('storyboard.uploadmodal.filepond.taptoretry')}
                        labelFileProcessingAborted={t('"storyboard.uploadmodal.filepond.fileprocessingaborted')}
                        labelFileLoading={t('storyboard.uploadmodal.filepond.fileloading')}
                        labelFileLoadError={t('storyboard.uploadmodal.filepond.fileloaderror')}
                        labelFileProcessingComplete={t('storyboard.uploadmodal.filepond.fileprocessingcomplete')}
                        labelFileProcessingError={t('storyboard.uploadmodal.filepond.fileprocessingerror')}
                        labelFileProcessingRevertError={t('storyboard.uploadmodal.filepond.fileprocessingreverterror')}
                        labelFileRemoveError={t('storyboard.uploadmodal.filepond.fileremoveerror')}
                        labelTapToUndo={t('storyboard.uploadmodal.filepond.taptoundo')}
                        labelButtonRemoveItem={t('storyboard.uploadmodal.filepond.buttonremoveitem')}
                        labelButtonAbortItemLoad={t('storyboard.uploadmodal.filepond.buttonabortitemload')}
                        labelButtonRetryItemLoad={t('storyboard.uploadmodal.filepond.buttonretryitemload')}
                        labelButtonAbortItemProcessing={t('storyboard.uploadmodal.filepond.buttonabortitemprocessing')}
                        labelButtonUndoItemProcessing={t('storyboard.uploadmodal.filepond.buttonundoitemprocessing')}
                        labelButtonRetryItemProcessing={t('storyboard.uploadmodal.filepond.buttonretryitemprocessing')}
                        labelButtonProcessItem={t('storyboard.uploadmodal.filepond.buttonprocessitem')}

                        ref={ref => (this.pondaudio = ref)}
                        files={this.state.audiofiles}
                        allowMultiple={false}
                        instantUpload={false}
                        allowReorder={true}
                        allowReplace={false}
                        credits={false}
                        allowRevert={false}
                        maxFileSize={10000000}
                        maxFiles={1}
                        acceptedFileTypes={['audio/mpeg']}
                        maxParallelUploads={1}
                        onprocessfiles={() => { this.setState({ audiofiles: null }); this.props.mediasCallback() }}

                        server={{
                          url: '/resource/storyboard',
                          process: {
                            headers: {
                              'X-File-Upload': JSON.stringify({ referenceId: this.props.hash, resourceMedia: 'audio', resourceType: 'music' })
                            }
                          }
                        }}
                        oninit={() => this.handleInit()}
                        onupdatefiles={fileItems => {
                          // Set currently active file objects to this.state
                          this.setState({
                            audiofiles: fileItems.map(fileItem => fileItem.file)
                          });
                        }}
                      />
                    </div>
                  </form>



                  <div style={{ textAlign: "center", color: "#aaa" }}><small>&copy; joobster gmbh, 2021</small></div>
                </div>


              </CardBody>
            </Card>


	          </ModalBody>
          <ModalFooter>


			<Button  color="primary" onClick={() => this.toggle()}>{t('clips.videocreationmodal.close')}</Button>



          </ModalFooter>



        </Modal>


      </div>
    );
  }
}



export default withSnackbar(withTranslation()(withStyles(styles)(UploadModal)));
