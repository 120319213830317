import React, {Component} from 'react'
import { InputGroup, InputGroupAddon, Button,  InputGroupText, Input } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import '../../i18n'
import { withTranslation } from 'react-i18next'
const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  textField: {
    width: 200,
  },
  textFieldMiddle: {
    width: 300,
  },
  textFieldLong: {
    width: 500,
  },
});



class FillShot extends Component{

  constructor(props){
    super(props)
    this.state = {
      seconds: this.props.currentvalue,

    }

    this.setSeconds = this.setSeconds.bind(this)
  }


  static getDerivedStateFromProps(props,state){


    if (parseInt(props.currentvalue) !== parseInt(state.seconds)){
      return {
        seconds: props.currentvalue
      }
    }

    return null


  }





/*

  componentDidMount(){
    if ( this.props.currentvalue){
    this.setState({
      seconds: this.props.currentvalue
    })
  }
  }
*/


  turnSecondsIntoCharacters(secs){
    // 10 Seconds are approximately 145Characters
    // 45 Seconds are 653 Characters
    // 60 Seconds are 870 Characters
    // 90 Seconds are 1305 Characters
    // 1 Second is approx 15 Characters

    //let multiplicator = 15

    //let characters =  parseInt(this.state.seconds) * multiplicator

    const {t} = this.props;
    let replaceString = "*" +t('general.placeholder') + "*"
    let repValue = secs //this.state.seconds
    //this.setState({ seconds: 0})

    return  replaceString.repeat(repValue)


  }



   setSeconds(e){
    console.log('seconds: ', e.target.value)
    this.setState({
      seconds: e.target.value
    })

    let replacestr = this.turnSecondsIntoCharacters(e.target.value)

   this.props.callback(replacestr)

  }

  render(){
    const {t} = this.props;
    return(

      <InputGroup className={this.props.classes.textField} >
        <Input  min={0} max={1000} type="number" step="1"  value={this.state.seconds} onChange={
          this.setSeconds
        }
        />
        {
          /*
       <InputGroupAddon addonType="append">
          <Button color="primary" onClick={() => this.props.callback(this.turnSecondsIntoCharacters())}>{t('storyboard.storyboardcreator.fillshot.add')}</Button>
        </InputGroupAddon>
                  */
      }


      </InputGroup>

    )
  }

}


export default withTranslation()(withStyles(styles)(FillShot));
